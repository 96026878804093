<template>
  <v-app-bar
    id="menu-app-bar"
    app
    fixed
    class="v-bar--underline"
    color="var(--primary-black-color)"
    :clipped-left="$vuetify.rtl"
    :clipped-right="!$vuetify.rtl"
    :height="barHeight"
  >
    <div class="bar-wrap d-flex align-center justify-space-between">
      <img class="logo cursor-pointer" src="@/assets/logo-yellow.svg" @click="navigatePush('')">
      <div v-if="!isMobileView" class="d-flex align-center h-100">
        <a class="d-flex align-center h-100 menu-item px-4" v-for="(item, index) in menus" :key="index" :href="`#${item.id}`">
          {{ item.name }}
        </a>
      </div>
    </div>
    <v-app-bar-nav-icon v-if="isMobileView" class="hidden-md-and-up" style="color: var(--primary-color)" @click="drawer = !drawer" />
  </v-app-bar>
</template>

<script>
export default {
  name: 'MainBar',
  components: {
  },
  data() {
    return {
      menus: [
        { name: this.$t('mainMenu.lbl.aboutUs'), id: 'about-us' },
        { name: this.$t('mainMenu.lbl.ourIngredients'), id: 'our-ingredients' },
        { name: this.$t('mainMenu.lbl.ourMenu'), id: 'our-menu' },
        { name: this.$t('mainMenu.lbl.ourCoffee'), id: 'our-coffee' },
        { name: this.$t('mainMenu.lbl.location'), id: 'location' },
      ],
    }
  },
  computed: {
    drawer: {
      get() {
        return this.$store.state.app.drawer;
      },
      set(value) {
        this.$store.commit('SET_DRAWER', value);
      },
    },
    barHeight() {
      return this.isMobileView ? 65 : 65;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../../styles/mixin.scss';

#menu-app-bar::v-deep {
  box-shadow: none;

  .v-toolbar__content {
    align-items: center;
    padding: 5px 0px 5px;
    @include for-sm {
      padding: 10px 20px 10px;
    }
  }
  .bar-wrap {
    height: 100%;
    width: 100%;
    padding: 0 60px;
    @include for-sm {
      padding: 0px;
    }

    .menu-item {
      color: #fff;
      font-size: 14px;
      text-decoration: none;
    }
  }
}
.logo {
  max-width: 55px;
  width: 100%;
}
</style>